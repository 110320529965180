// sortowanie pól po wartości pierwszego podpola
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "item", // element to sort contained directly in one Node
    "sortBy" // element with value to sort in `this.itemTarget`
  ]

  // sort by value of first `this.sortByTarget` in `this.itemTarget`
  sort() {
    this.itemTargets.map(item => {
      return {
        el: item,
        value: this.sortByTargets.find(el => { return item.contains(el) }).value
      }
    }).sort((a, b) => {
      return a.value.localeCompare(b.value)
    }).forEach(item => {
      item.el.parentNode.appendChild(item.el)
    });

    this.dispatch("after-sort");
  }
}
